@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

::-webkit-scrollbar-thumb {
    background: #2c033b;
    border-radius: 0.5rem;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

::-webkit-scrollbar {
    width: 10px;
}
